import { Flex } from '@chakra-ui/react'
import { DOPPEL_DARK_SECONDARY } from '../../utils/style'

// Dimmed background and overlay container for detail modal that shows the report breakdown
export default function DetailModal({ overlayRef, handleClickOutside, ...props }) {
  const handleClickOutsideFunc = (event) => {
    if (overlayRef?.current && !overlayRef.current.contains(event?.target)) {
      handleClickOutside()
    }
  }

  return (
    <Flex
      align="center"
      backdropFilter="blur(2px)"
      bg="rgba(0, 0, 0, 0.5)"
      height="100vh"
      justify="center"
      left="0px"
      marginTop="0px !important"
      onClick={handleClickOutsideFunc}
      overflow="auto"
      position="fixed"
      top="0px"
      width="100vw"
      zIndex="2"
    >
      <Flex
        bgColor={DOPPEL_DARK_SECONDARY}
        boxShadow={'2xl'}
        height="80vh"
        marginLeft={{ base: '0', md: '110' }}
        overflow={'hidden'}
        position="relative"
        ref={overlayRef}
        rounded={'lg'}
        {...props}
      >
        {props.children}
      </Flex>
    </Flex>
  )
}

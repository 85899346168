import dynamic from 'next/dynamic'

type DoppelJsonViewProps = {
  src: any
  collapsed?: boolean
  enableClipboard?: boolean
  displayDataTypes?: boolean
}

export const DoppelJsonView = ({
  src,
  collapsed = false,
  enableClipboard = true,
  displayDataTypes = false,
}: DoppelJsonViewProps) => {
  const DynamicReactJson = dynamic(import('react-json-view'), { ssr: false })

  return (
    <DynamicReactJson
      collapsed={collapsed}
      displayDataTypes={displayDataTypes}
      enableClipboard={enableClipboard}
      src={src}
      theme={'tomorrow'}
    />
  )
}

import {
  VStack,
  Flex,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Button,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import {
  copyTextToClipboard,
  fallbackCopyTextToClipboard,
  pythonFormat as pythonFormatAndHighlightInput,
} from '../../utils'
import {
  DOPPEL_BREACH_RED,
  DOPPEL_CYBER_BLUE,
  DOPPEL_INTERNAL_PURPLE,
} from '../../utils/style'
import DoppelFormLabel from '../shared/forms/doppel_form_label'

export function TemplateSection({
  label,
  template,
  moreInfoInputs,
  alwaysHighlight = false,
}) {
  const [showTemplate, setShowTemplate] = useState(false)
  if (!template) {
    return <></>
  }

  const templateBuilder = pythonFormatAndHighlightInput(template, moreInfoInputs)
  let linesToRender = null

  if (showTemplate || alwaysHighlight) {
    let templateWithHighlighting = template
    templateWithHighlighting = templateWithHighlighting.replaceAll(
      '{',
      `<span style='background-color:${DOPPEL_CYBER_BLUE};'>{`,
    )
    templateWithHighlighting = templateWithHighlighting.replaceAll('}', '}</span>')
    linesToRender = templateWithHighlighting.split('\\n')
  } else {
    linesToRender = templateBuilder.split('\\n')
  }

  return (
    <VStack align="left" width="100%">
      <DoppelFormLabel formLabel={label} />

      <Flex
        alignItems="center"
        borderColor={DOPPEL_INTERNAL_PURPLE}
        borderRadius="lg"
        borderWidth="2px"
        fontSize="14px"
        justifyContent="space-between"
        padding="8px"
        width="100%"
      >
        <Flex direction="column">
          {linesToRender.map((line, index) => (
            <Text
              dangerouslySetInnerHTML={{
                __html: line,
              }}
              key={index}
              paddingY="2"
            ></Text>
          ))}

          <Flex justifyContent={'flex-start'} width="100%">
            <Button
              h="1.75rem"
              onClick={() => {
                copyTextToClipboard(
                  linesToRender
                    .join('\n')
                    .replaceAll(
                      `<span style='background-color:${DOPPEL_CYBER_BLUE};'>`,
                      '',
                    )
                    .replaceAll(
                      `<span style='background-color:${DOPPEL_BREACH_RED};'>`,
                      '',
                    )
                    .replaceAll('</span>', ''),
                )
              }}
              size="sm"
            >
              Copy
            </Button>

            {!alwaysHighlight && (
              <Button
                aria-label="Toggle Template"
                h="1.75rem"
                ml={2}
                onClick={() => {
                  setShowTemplate(!showTemplate)
                }}
                size="sm"
              >
                {showTemplate ? 'Hide Template' : 'Show Template'}
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </VStack>
  )
}

export function EnforcementTemplateRow({ label, dbValue, formRegister, formWatch }) {
  const currentValue = formWatch(label) == '' ? null : formWatch(label)
  return (
    <>
      <Text fontSize="12px">
        <b>{label}</b>
      </Text>

      <InputGroup size="md">
        <Input
          alignItems="center"
          borderColor={
            currentValue == undefined || currentValue == dbValue
              ? '#DEDEDE'
              : DOPPEL_CYBER_BLUE
          }
          borderRadius="lg"
          borderWidth="2px"
          defaultValue={dbValue}
          fontSize="14px"
          isDisabled={true}
          justifyContent="space-between"
          padding="8px"
          placeholder={'null'}
          width="100%"
          {...formRegister(label)}
        />

        <InputRightElement width="4.5rem">
          <Button
            h="1.75rem"
            onClick={() => fallbackCopyTextToClipboard(dbValue)}
            size="sm"
          >
            Copy
          </Button>
        </InputRightElement>
      </InputGroup>
    </>
  )
}

import { withApollo } from '@apollo/react-hoc'
import {
  Checkbox,
  Flex,
  Heading,
  VStack,
  FormControl,
  Stack,
  RadioGroup,
  Radio,
  Spacer,
  Select,
  Text,
  Collapse,
  Button,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react'
import SpoofDetailImagePreview from '../web2/detail_modal/spoof_detail_image_preview'
import {
  CustomerType,
  EnforcementUrlType,
  ProductType,
  ReportStatus,
} from '../../generated/enums'
import { useSelectedSpoofReport } from '../../hooks/spoof_report_table_filters'
import { getOrgData } from '../../hooks/id_token_claims'
import {
  DEFAULT_SOCIAL_MEDIA_ARCHETYPE,
  getRootAndHost,
  PRODUCT_TYPE_TO_ENFORCEMENT_TYPE,
  removePrefixFromArchetype,
  renderFlaggedUrl,
  renderRubricLink,
} from '../../utils/enforcement_utils'
import DoppelFormLabel from '../shared/forms/doppel_form_label'
import {
  useGetContentTypesByEnforcementPlatformAndContentTypeLazyQuery,
  useGetContentTypesByEnforcementPlatformLazyQuery,
  useGetEnforcementMetadataQuery,
  useCheckEnforcementPlatformTypeByMatchStringLazyQuery,
  useGetEnforcementTypesByContentTypeLazyQuery,
  useGetTrademarksQuery,
} from '../../generated/graphql'
import { DOPPEL_BREACH_RED, DOPPEL_CYBER_BLUE } from '../../utils/style'
import { useState, useRef, useEffect } from 'react'
import {
  ENFORCEMENT_TYPE,
  SUGGESTED_ENFORCEMENT_ENABLED_PRODUCTS,
} from '../../utils/constants'
import SuggestedEnforcementsDisplay from './suggested_enforcements_display'
import DetailModal from '../shared_reports_table/detail_modal'
import { capitalizeFirstLetter } from '../../utils/string_utils'
import DetailNavBar from '../shared/back_button'
import {
  DomainsFormData,
  AppsFormData,
  SocialMediaFormData,
  PaidAdFormData,
} from '@/utils/suggested_enforcements/suggested_enforcement_types'
import { CRYPTO_WALLET_BLOCKING_ENABLED_URL_TYPES } from '@/utils/suggested_enforcements/suggested_enforcement_constants'

type Props = {
  closeSuggestedEnforcementsForm: () => void
  returnToTableView: any
  addSuggestedEnforcementsToForm: any
  currentStagedEnforcements: any
  useDefaults?: boolean
  client: any
  product: string
}

export const NO_TRADEMARK_SELECTED = 'No Trademark'

export const renderTrademarkSelector = (
  trademarkOptions,
  selectedMark,
  setSelectedId,
  setSelectedMark,
) => {
  return (
    <Select
      maxWidth={'50%'}
      onChange={(e) => {
        const selectedValue = e.target.value
        if (selectedValue === NO_TRADEMARK_SELECTED) {
          setSelectedId('')
          setSelectedMark(NO_TRADEMARK_SELECTED)
        } else {
          const selectedTrademark = Object.values(trademarkOptions).find(
            (trademark: any) => trademark.mark === selectedValue,
          ) as any
          setSelectedId(selectedTrademark?.id)
          setSelectedMark(selectedValue)
        }
      }}
      placeholder="Select Trademark"
      size={'sm'}
      value={selectedMark}
    >
      <option value={NO_TRADEMARK_SELECTED}>{NO_TRADEMARK_SELECTED}</option>

      {Object.values(trademarkOptions).map((trademark: any) => (
        <option key={trademark.mark} value={trademark.mark}>
          {trademark.mark}
        </option>
      ))}
    </Select>
  )
}

const URL_TYPE_TO_HELPER_TEXT = {
  [EnforcementUrlType.MALICIOUS]:
    'The domain itself is infringing or suspicious. Usually this takes the form of a typosquat url with common misspellings, swapping characters, etc (Ex: Facebook-login.com, facebok.com, etc.)',
  [EnforcementUrlType.COMPROMISED]:
    'The domain is legit but it was compromised/hacked to host malicious content. Usually this takes the form of a path url or a subdomain of a legit/known domain. (Ex: www.facebook.com/wp-admin/payme.html, payme.facebook.com)',
  [EnforcementUrlType.SHORTENER]:
    'A shortened version of a URL that redirects to a suspicious site. (Ex: Bit.ly, cutt.ly, t.co, etc.)',
  [EnforcementUrlType.BUILDER]:
    'Malicious content is hosted on a platform that is used for website building. (Ex: github.io, gitbook.io, vercel.app, pages.dev, etc.)',
}

// Products for which list of enforcement types are static and not fetched from the backend
const STATIC_ENFORCEMENT_TYPE_LIST_PRODUCTS = [
  ProductType.MOBILE_APP,
  ProductType.PAID_AD,
]

const COPYRIGHT_ENABLED_PRODUCTS = [ProductType.DOMAIN, ProductType.SOCIAL_MEDIA]

const CRYPTO_ENABLED_PRODUCTS = [ProductType.DOMAIN]

const SUSPICION_SCORE_THRESHOLD = 0.6

const SuggestedEnforcementsForm: React.FC<Props> = ({
  closeSuggestedEnforcementsForm,
  returnToTableView,
  addSuggestedEnforcementsToForm,
  currentStagedEnforcements,
  product,
}) => {
  const overlayRef = useRef<HTMLInputElement>()
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const [orgData] = getOrgData()
  const [isTrademarkQuestionsExpanded, setIsTrademarkQuestionsExpanded] =
    useState(false)
  const [isPhishingQuestionsExpanded, setIsPhishingQuestionsExpanded] = useState(false)
  const [isViolatingCustomerRubric, setIsViolatingCustomerRubric] = useState(
    selectedSpoofReport?.report_status === ReportStatus.REPORTED,
  )

  const [urlType, setUrlType] = useState('')
  const [isPhishingSelected, setIsPhishingSelected] = useState(false)
  const [isTrademarkSelected, setIsTrademarkSelected] = useState(false)
  const [isImpersonationSelected, setIsImpersonationSelected] = useState(false)
  const [isProductMisuseSelected, setIsProductMisuseSelected] = useState(false)
  const [isCounterfeitSelected, setIsCounterfeitSelected] = useState(false)

  const [isCryptoWalletBlockingSelected, setIsCryptoWalletBlockingSelected] = useState(
    orgData?.customer_type === CustomerType.CRYPTO,
  )

  const [selectedTrademarkId, setSelectedTrademarkId] = useState('')
  const [selectedTrademarkMark, setSelectedTrademarkMark] = useState('')

  const toast = useToast()

  // State variables for trademark additional questions
  const [isLogoUsed, setIsLogoUsed] = useState(false)
  const [isCompanyNameUsed, setIsCompanyNameUsed] = useState(false)
  const [isSloganUsed, setIsSloganUsed] = useState(false)
  const [isSpecialPhraseUsed, setIsSpecialPhraseUsed] = useState(false)
  const [isProductUsed, setIsProductUsed] = useState(false)
  const [isProductNameUsed, setIsProductNameUsed] = useState(false)

  // State variables for phishing additional questions
  const [isAskingLoginInfo, setIsAskingLoginInfo] = useState(false)
  const [isAskingCardInfo, setIsAskingCardInfo] = useState(false)
  const [isAskingSensitiveInfo, setIsAskingSensitiveInfo] = useState(false)
  const [isAskingCryptoSeedPhraseInfo, setIsAskingCryptoSeedPhraseInfo] =
    useState(false)
  const [isAskingCryptoPrivateKeyInfo, setIsAskingCryptoPrivateKeyInfo] =
    useState(false)

  const [showSuggestedEnforcementsDisplay, setShowSuggestedEnforcementsDisplay] =
    useState(false)
  const [fetchContentTypesQuery] = useGetContentTypesByEnforcementPlatformLazyQuery()

  const [fetchContentTypesByArchetypeQuery] =
    useGetContentTypesByEnforcementPlatformAndContentTypeLazyQuery()
  // Map from content type display name to content type
  const [allowedContentTypes, setAllowedContentTypes] = useState({})

  const [allowedEnforcementTypes, setAllowedEnforcementTypes] = useState([])
  const [selectedContentTypeDisplayName, setSelectedContentTypeDisplayName] =
    useState('')

  const [fetchEnforcementTypesByContentTypeQuery] =
    useGetEnforcementTypesByContentTypeLazyQuery()

  const [isContentTypesLoading, setIsContentTypesLoading] = useState(true)

  const [checkEnforcementPlatformTypeByMatchStringQuery] =
    useCheckEnforcementPlatformTypeByMatchStringLazyQuery()

  useGetEnforcementTypesByContentTypeLazyQuery()

  const shouldGoDirectlyToSuggestedEnforcementsDisplay =
    product === ProductType.SOCIAL_MEDIA &&
    !isContentTypesLoading &&
    Object.keys(allowedContentTypes).length === 0

  useEffect(() => {
    const fetchData = async () => {
      if (product === ProductType.SOCIAL_MEDIA) {
        setIsContentTypesLoading(true)
        let response
        if (
          selectedSpoofReport.platform_subtype.archetype !=
          DEFAULT_SOCIAL_MEDIA_ARCHETYPE
        ) {
          const content_type = removePrefixFromArchetype(
            selectedSpoofReport.platform_subtype.archetype,
          )
          response = await fetchContentTypesByArchetypeQuery({
            variables: {
              platform: selectedSpoofReport.platform_subtype.platform,
              content_type,
            },
          })
          if (response.data && response.data.enforcement_types.length > 0) {
            setSelectedContentTypeDisplayName(
              response.data.enforcement_types[0].content_type_display_name,
            )
          }
        } else {
          response = await fetchContentTypesQuery({
            variables: {
              platform: selectedSpoofReport.type,
            },
          })
        }
        if (response.data) {
          const enforcementTypes = response.data.enforcement_types
          const contentTypesDict = enforcementTypes.reduce(
            (acc, { content_type, content_type_display_name }) => {
              acc[content_type_display_name] = content_type
              return acc
            },
            {},
          )
          setAllowedContentTypes(contentTypesDict)
        }
        setIsContentTypesLoading(false)
      }
    }
    fetchData()
  }, [product, selectedSpoofReport.type])

  useEffect(() => {
    if (STATIC_ENFORCEMENT_TYPE_LIST_PRODUCTS.includes(product as ProductType)) {
      setAllowedEnforcementTypes(PRODUCT_TYPE_TO_ENFORCEMENT_TYPE[product])
    } else if (product == ProductType.DOMAIN) {
      if (enforcementMetadataAllowedDomainEnforcementTypes.length === 0) {
        setAllowedEnforcementTypes(PRODUCT_TYPE_TO_ENFORCEMENT_TYPE[product])
      } else {
        setAllowedEnforcementTypes(enforcementMetadataAllowedDomainEnforcementTypes)
      }
    } else if (
      product === ProductType.SOCIAL_MEDIA &&
      selectedContentTypeDisplayName != ''
    ) {
      const fetchData = async () => {
        const response = await fetchEnforcementTypesByContentTypeQuery({
          variables: {
            platform: selectedSpoofReport.type,
            content_type_display_name: selectedContentTypeDisplayName,
          },
        })
        if (response.data) {
          const enforcementTypes = response.data.enforcement_types
          const uniqueEnforcementTypes = Array.from(
            new Set(enforcementTypes.map((type) => type.type)),
          )
          setAllowedEnforcementTypes(uniqueEnforcementTypes)
        }
      }
      resetEnforcementTypeSelections()
      fetchData()
    }
  }, [product, selectedContentTypeDisplayName])

  useEffect(() => {
    if (isDomainAlert()) {
      const { root, host } = getRootAndHost(selectedSpoofReport?.flagged_url)
      const fetchData = async () => {
        const response = await checkEnforcementPlatformTypeByMatchStringQuery({
          variables: {
            match_string: root,
          },
        })
        if (response.data) {
          const enforcementPlatforms = response.data.enforcement_platforms
          if (enforcementPlatforms.length > 0) {
            const enforcementPlatform = enforcementPlatforms[0]
            setUrlType(enforcementPlatform.types?.[0] || '')
          }
        }
      }
      fetchData()
    }
  }, [])

  const isDomainAlert = () => {
    return product === ProductType.DOMAIN
  }

  const ENFORCEMENT_TYPE_TO_ADDITIONAL_QUESTIONS = {
    [ENFORCEMENT_TYPE.TRADEMARK]: [
      {
        question: "Is the company's logo used on the site?",
        state: isLogoUsed,
        setState: setIsLogoUsed,
      },
      {
        question: "Is the company's name used on the site?",
        state: isCompanyNameUsed,
        setState: setIsCompanyNameUsed,
      },
      {
        question: "Is the company's slogan used on the site?",
        state: isSloganUsed,
        setState: setIsSloganUsed,
      },
      {
        question: 'Is there a special phrase relating to the company used on the site?',
        state: isSpecialPhraseUsed,
        setState: setIsSpecialPhraseUsed,
      },
      {
        question: "Is the company's product used on the site?",
        state: isProductUsed,
        setState: setIsProductUsed,
      },
      {
        question: "Is the company's product name used on the site?",
        state: isProductNameUsed,
        setState: setIsProductNameUsed,
      },
    ],
    [ENFORCEMENT_TYPE.PHISHING]: [
      {
        question: 'Does the site mimic a known login page?',
        state: isAskingLoginInfo,
        setState: setIsAskingLoginInfo,
      },
      {
        question: 'Is the site asking for card information?',
        state: isAskingCardInfo,
        setState: setIsAskingCardInfo,
      },
      {
        question: 'Is the site soliciting sensitive/private information?',
        state: isAskingSensitiveInfo,
        setState: setIsAskingSensitiveInfo,
      },
    ],
  }

  const PHISHING_CRYPTO_QUESTIONS = [
    {
      question: '(Crypto) Does the site request seed phrases?',
      state: isAskingCryptoSeedPhraseInfo,
      setState: setIsAskingCryptoSeedPhraseInfo,
    },
    {
      question: '(Crypto) Does the site ask for private login keys?',
      state: isAskingCryptoPrivateKeyInfo,
      setState: setIsAskingCryptoPrivateKeyInfo,
    },
  ]

  const { data: enforcementMetadata } = useGetEnforcementMetadataQuery({
    variables: {
      org_id: orgData?.id,
    },
  })

  const enforcementMetadataAllowedDomainEnforcementTypes =
    enforcementMetadata?.enforcement_metadata[0]?.allowed_domain_enforcement_types || []

  function getMaxSuspicionScore() {
    let highestSuspicionScore = 0
    selectedSpoofReport?.spoof_matches?.forEach((match) => {
      if (match.suspicion_score > highestSuspicionScore) {
        highestSuspicionScore = match.suspicion_score
      }
    })
    return highestSuspicionScore
  }

  const suspicionScore = getMaxSuspicionScore()
  const [isCopyrightSelected, setIsCopyrightSelected] = useState(
    !allowedEnforcementTypes.includes(ENFORCEMENT_TYPE.COPYRIGHT)
      ? false
      : suspicionScore > SUSPICION_SCORE_THRESHOLD,
  )

  const collectFormResponses = () => {
    const formData = {
      customer_rubric_info: {
        is_violating_customer_rubric: isViolatingCustomerRubric,
        rubric_link: orgData?.rubric_link || '',
      },
      trademark_info: {
        is_trademark_selected: isTrademarkSelected,
        selected_trademark_id: selectedTrademarkId,
      },
    }

    if (isTrademarkQuestionsExpanded) {
      formData['trademark_info']['additional_questions'] = {
        company_assets: {
          logo_used: isLogoUsed,
          company_name_used: isCompanyNameUsed,
          slogan_used: isSloganUsed,
          special_phrase_used: isSpecialPhraseUsed,
        },
        product: {
          product_used: isProductUsed,
          product_name_used: isProductNameUsed,
        },
      }
    }

    const copyrightInfo = {
      is_copyright_selected: isCopyrightSelected,
      report_suspicion_score: suspicionScore,
    }

    if (COPYRIGHT_ENABLED_PRODUCTS.includes(product as ProductType)) {
      formData['copyright_info'] = copyrightInfo
    }

    if (isDomainAlert()) {
      formData['phishing_info'] = {
        is_phishing_selected: isPhishingSelected,
      }
      formData['url_type'] = urlType
      if (
        isPhishingSelected &&
        CRYPTO_WALLET_BLOCKING_ENABLED_URL_TYPES.includes(
          urlType as EnforcementUrlType,
        ) &&
        orgData?.customer_type === CustomerType.CRYPTO
      ) {
        formData['phishing_info']['is_crypto_wallet_blocking_selected'] =
          isCryptoWalletBlockingSelected
      }

      if (isPhishingQuestionsExpanded) {
        formData['phishing_info']['additional_questions'] = {}
        formData['phishing_info']['additional_questions']['info_stealing'] = {
          asking_login_info: isAskingLoginInfo,
          asking_card_info: isAskingCardInfo,
          asking_sensitive_info: isAskingSensitiveInfo,
        }

        if (orgData?.customer_type === CustomerType.CRYPTO) {
          formData['phishing_info']['additional_questions']['crypto'] = {
            asking_seed_phrase_info: isAskingCryptoSeedPhraseInfo,
            asking_private_key_info: isAskingCryptoPrivateKeyInfo,
          }
        }
      }
    } else if (product === ProductType.SOCIAL_MEDIA) {
      formData['content_type_info'] = {
        content_type: allowedContentTypes[selectedContentTypeDisplayName],
        content_type_display_name: selectedContentTypeDisplayName,
      }
      formData['product_misuse_info'] = {
        is_product_misuse_selected: isProductMisuseSelected,
      }
      formData['impersonation_info'] = {
        is_impersonation_selected: isImpersonationSelected,
      }
      formData['counterfeit_info'] = {
        is_counterfeit_selected: isCounterfeitSelected,
      }
    } else if (product === ProductType.MOBILE_APP) {
      formData['dmca_info'] = copyrightInfo
    } else if (product === ProductType.PAID_AD) {
      formData['phishing_info'] = {
        is_phishing_selected: isPhishingSelected,
      }
      if (isPhishingQuestionsExpanded) {
        formData['phishing_info']['additional_questions'] = {}
        formData['phishing_info']['additional_questions']['info_stealing'] = {
          asking_login_info: isAskingLoginInfo,
          asking_card_info: isAskingCardInfo,
          asking_sensitive_info: isAskingSensitiveInfo,
        }
      }
    }

    if (isDomainAlert()) {
      return formData as DomainsFormData
    } else if (product === ProductType.MOBILE_APP) {
      return formData as AppsFormData
    } else if (product === ProductType.SOCIAL_MEDIA) {
      return formData as SocialMediaFormData
    } else if (product === ProductType.PAID_AD) {
      return formData as PaidAdFormData
    }

    return formData
  }

  const [formData, setFormData] = useState({})

  const onSubmit = () => {
    let currentFormData = {}
    if (!shouldGoDirectlyToSuggestedEnforcementsDisplay) {
      currentFormData = collectFormResponses()
    }
    setFormData(currentFormData)
    setShowSuggestedEnforcementsDisplay(true)
  }

  const resetEnforcementTypeSelections = () => {
    // Reset enforcement type selections
    setIsPhishingSelected(false)
    setIsTrademarkSelected(false)
    setIsImpersonationSelected(false)
    setIsProductMisuseSelected(false)
    setIsCounterfeitSelected(false)
    setIsCopyrightSelected(false)

    // Reset values related to enforcement types
    setIsCryptoWalletBlockingSelected(orgData?.customer_type === CustomerType.CRYPTO)
    setSelectedTrademarkId('')
    setSelectedTrademarkMark('')

    // Reset trademark additional questions
    setIsLogoUsed(false)
    setIsCompanyNameUsed(false)
    setIsSloganUsed(false)
    setIsSpecialPhraseUsed(false)
    setIsProductUsed(false)
    setIsProductNameUsed(false)

    // Reset phishing additional questions
    setIsAskingLoginInfo(false)
    setIsAskingCardInfo(false)
    setIsAskingSensitiveInfo(false)
    setIsAskingCryptoSeedPhraseInfo(false)
    setIsAskingCryptoPrivateKeyInfo(false)
  }

  useEffect(() => {
    if (
      shouldGoDirectlyToSuggestedEnforcementsDisplay &&
      !showSuggestedEnforcementsDisplay
    ) {
      onSubmit()
    }
  }, [shouldGoDirectlyToSuggestedEnforcementsDisplay, showSuggestedEnforcementsDisplay])

  const generateCheckbox = (
    defaultChecked: boolean,
    onChange: () => void,
    interiorComponent: JSX.Element,
  ) => {
    return (
      <Checkbox colorScheme="green" isChecked={defaultChecked} onChange={onChange}>
        {interiorComponent}
      </Checkbox>
    )
  }

  const generateEnforcementTypeCheckbox = (
    enforcementType: string,
    value: boolean,
    setValue: (value: boolean) => void,
    defaultChecked: boolean,
    helperText: string,
  ) => {
    const interiorComponent = (
      <DoppelFormLabel
        formLabel={enforcementType}
        helperText={`${helperText} (Generate a ${enforcementType} enforcement)`}
      />
    )

    return generateCheckbox(defaultChecked, () => setValue(!value), interiorComponent)
  }

  const renderEnforcementTypeAdditionalDetails = (
    isComponentExpanded: boolean,
    setIsComponentExpanded: (value: boolean) => void,
    enforcementType: string,
    conditionalQuestions: {
      condition: boolean
      questions: {
        question: string
        state: boolean
        setState: (value: boolean) => void
      }[]
    } | null,
  ) => {
    const additionalQuestions =
      ENFORCEMENT_TYPE_TO_ADDITIONAL_QUESTIONS[enforcementType]

    return (
      <Stack>
        {generateCheckbox(
          isComponentExpanded,
          () => {
            setIsComponentExpanded(!isComponentExpanded)
          },
          <DoppelFormLabel
            formLabel={`(OPTIONAL) Fill out reasons to generate ${enforcementType} enforcement`}
            helperText={`It would be greatly appreciated if you could fill out the reasons to generate the ${enforcementType} enforcement for auditability and replicability.`}
          />,
        )}

        <Collapse in={isComponentExpanded}>
          <SimpleGrid columns={2} spacing={6}>
            {additionalQuestions.map((additionalQuestion) =>
              generateCheckbox(
                additionalQuestion.state,
                () => {
                  additionalQuestion.setState(!additionalQuestion.state)
                },
                <span>{additionalQuestion.question}</span>,
              ),
            )}

            {conditionalQuestions?.condition &&
              conditionalQuestions.questions.map((conditionalQuestion) =>
                generateCheckbox(
                  conditionalQuestion.state,
                  () => {
                    conditionalQuestion.setState(!conditionalQuestion.state)
                  },
                  <span>{conditionalQuestion.question}</span>,
                ),
              )}
          </SimpleGrid>
        </Collapse>
      </Stack>
    )
  }

  const generateEnforcementTypeFormLabel = (enforcementType: string) => {
    const capitalizedEnforcementType = capitalizeFirstLetter(enforcementType)

    return (
      <DoppelFormLabel
        formLabel={`${capitalizedEnforcementType} Details`}
        helperText={`${capitalizedEnforcementType} enforcements will be generated for this site. Please fill out additional details.`}
      />
    )
  }

  const trademarksWhereClause = {
    spoofing_entity_id: { _eq: selectedSpoofReport?.spoofing_entity?.id },
  }

  const { data: entitiesData, loading } = useGetTrademarksQuery({
    variables: {
      offset: 0,
      limit: null,
      where: trademarksWhereClause,
    },
  })

  const entities = (entitiesData && entitiesData.trademarks) || []

  const isDisabledByMultiselectEnforcementType =
    product == ProductType.PAID_AD && isPhishingSelected && isTrademarkSelected

  const isNextButtonDisabled =
    !isViolatingCustomerRubric ||
    (product == ProductType.DOMAIN && !urlType) ||
    (product == ProductType.SOCIAL_MEDIA && !selectedContentTypeDisplayName) ||
    (isTrademarkSelected && !selectedTrademarkMark) ||
    (!isPhishingSelected &&
      !isTrademarkSelected &&
      !isCopyrightSelected &&
      !isImpersonationSelected &&
      !isProductMisuseSelected &&
      !isCounterfeitSelected) ||
    isDisabledByMultiselectEnforcementType

  const isCryptoEnabledProductType = CRYPTO_ENABLED_PRODUCTS.includes(
    product as ProductType,
  )

  const TrademarkSection = (
    <Stack>
      <Stack>{generateEnforcementTypeFormLabel(ENFORCEMENT_TYPE.TRADEMARK)}</Stack>

      {renderTrademarkSelector(
        entities,
        selectedTrademarkMark,
        setSelectedTrademarkId,
        setSelectedTrademarkMark,
      )}

      {selectedTrademarkId &&
        renderEnforcementTypeAdditionalDetails(
          isTrademarkQuestionsExpanded,
          setIsTrademarkQuestionsExpanded,
          ENFORCEMENT_TYPE.TRADEMARK,
          null,
        )}
    </Stack>
  )

  const EnforcementUrlTypeSelector = (
    <Stack>
      <FormControl isRequired>
        <DoppelFormLabel
          formLabel="URL Type"
          helperText="The category of the entity that is being enforced."
        />
      </FormControl>

      <RadioGroup
        onChange={(val) => setUrlType(val as EnforcementUrlType)}
        value={urlType}
      >
        <Flex>
          {Object.values(EnforcementUrlType).map((urlType) => (
            <>
              <Radio key={urlType} size="md" value={urlType}>
                <DoppelFormLabel
                  formLabel={urlType}
                  helperText={URL_TYPE_TO_HELPER_TEXT[urlType]}
                />
              </Radio>

              <Spacer />
            </>
          ))}
        </Flex>
      </RadioGroup>
    </Stack>
  )

  const ContentTypeSelector = (
    <Stack>
      <FormControl isRequired>
        <DoppelFormLabel
          formLabel="Content Type"
          helperText="The content type of the entity that is being enforced."
        />
      </FormControl>

      <RadioGroup
        onChange={(val) => setSelectedContentTypeDisplayName(val)}
        value={selectedContentTypeDisplayName}
      >
        <Flex>
          {Object.keys(allowedContentTypes).map((contentType) => (
            <>
              <Radio key={contentType} size="md" value={contentType}>
                {contentType}
              </Radio>

              <Spacer />
            </>
          ))}
        </Flex>
      </RadioGroup>
    </Stack>
  )

  const EnforcementTypeSelector = (
    <Stack>
      <FormControl isRequired>
        <DoppelFormLabel
          formLabel="Enforcement Types"
          helperText="Please select what types of enforcements are required for this site."
        />

        {allowedEnforcementTypes.includes(ENFORCEMENT_TYPE.COPYRIGHT) &&
        suspicionScore &&
        suspicionScore > SUSPICION_SCORE_THRESHOLD ? (
          <Text color={DOPPEL_BREACH_RED} fontSize={'small'}>
            {`We highly recommend generating a copyright enforcement. Suspicious level is ${Math.floor(
              suspicionScore * 100,
            )}%`}
          </Text>
        ) : null}
      </FormControl>

      <SimpleGrid columns={5} spacing={6}>
        {allowedEnforcementTypes.includes(ENFORCEMENT_TYPE.PHISHING) &&
          generateEnforcementTypeCheckbox(
            ENFORCEMENT_TYPE.PHISHING,
            isPhishingSelected,
            setIsPhishingSelected,
            isPhishingSelected,
            'Is this a phishing site?',
          )}

        {allowedEnforcementTypes.includes(ENFORCEMENT_TYPE.TRADEMARK) &&
          generateEnforcementTypeCheckbox(
            ENFORCEMENT_TYPE.TRADEMARK,
            isTrademarkSelected,
            setIsTrademarkSelected,
            isTrademarkSelected,
            'Does the site infringe upon any trademarks?',
          )}

        {(allowedEnforcementTypes.includes(ENFORCEMENT_TYPE.COPYRIGHT) ||
          allowedEnforcementTypes.includes(ENFORCEMENT_TYPE.DMCA)) &&
          generateEnforcementTypeCheckbox(
            allowedEnforcementTypes.includes(ENFORCEMENT_TYPE.DMCA)
              ? ENFORCEMENT_TYPE.DMCA
              : ENFORCEMENT_TYPE.COPYRIGHT,
            isCopyrightSelected,
            setIsCopyrightSelected,
            isCopyrightSelected,
            'Does this site copy a substantial amount of the original content?',
          )}

        {allowedEnforcementTypes.includes(ENFORCEMENT_TYPE.IMPERSONATION) &&
          generateEnforcementTypeCheckbox(
            ENFORCEMENT_TYPE.IMPERSONATION,
            isImpersonationSelected,
            setIsImpersonationSelected,
            isImpersonationSelected,
            'Is this site impersonating a known brand or executive of the company?',
          )}

        {allowedEnforcementTypes.includes(ENFORCEMENT_TYPE.PRODUCT_MISUSE) &&
          generateEnforcementTypeCheckbox(
            ENFORCEMENT_TYPE.PRODUCT_MISUSE,
            isProductMisuseSelected,
            setIsProductMisuseSelected,
            isProductMisuseSelected,
            'Is this site misusing the company’s product?',
          )}

        {allowedEnforcementTypes.includes(ENFORCEMENT_TYPE.COUNTERFEIT) &&
          generateEnforcementTypeCheckbox(
            ENFORCEMENT_TYPE.COUNTERFEIT,
            isCounterfeitSelected,
            setIsCounterfeitSelected,
            isCounterfeitSelected,
            'Is this site referencing fake or unauthorized products under the company’s brand?',
          )}

        {isDisabledByMultiselectEnforcementType && (
          <Text color={DOPPEL_BREACH_RED} fontSize={'small'}>
            {`Cannot select both Phishing and Trademark`}
          </Text>
        )}
      </SimpleGrid>
    </Stack>
  )

  const CustomerRubricSection = (
    <FormControl isRequired>
      <Stack>
        <DoppelFormLabel
          formLabel="Customer Rubric Violation"
          helperText="Please check if this entity is sufficiently violating the customer rubric."
        />

        <Checkbox
          colorScheme="green"
          defaultChecked={isViolatingCustomerRubric}
          onChange={() => setIsViolatingCustomerRubric(!isViolatingCustomerRubric)}
        >
          Is the site violating the customer rubric?
          {orgData?.rubric_link ? <> (See {renderRubricLink(orgData)})</> : null}
        </Checkbox>
      </Stack>
    </FormControl>
  )

  const PhishingSection = (
    <Stack>
      {generateEnforcementTypeFormLabel(ENFORCEMENT_TYPE.PHISHING)}

      {orgData?.customer_type === CustomerType.CRYPTO &&
        CRYPTO_WALLET_BLOCKING_ENABLED_URL_TYPES.includes(
          urlType as EnforcementUrlType,
        ) && (
          <FormControl isRequired>
            <Checkbox
              colorScheme="green"
              defaultChecked={isCryptoWalletBlockingSelected}
              onChange={() =>
                setIsCryptoWalletBlockingSelected(!isCryptoWalletBlockingSelected)
              }
            >
              <DoppelFormLabel
                formLabel={'Create crypto wallet blocking enforcements?'}
                helperText={
                  'Consider unchecking if site is not related to crypto wallets or if the site is phishing for generic login info, not crypto-specific info. Please review customer rubric for more details.'
                }
              />
            </Checkbox>
          </FormControl>
        )}

      {renderEnforcementTypeAdditionalDetails(
        isPhishingQuestionsExpanded,
        setIsPhishingQuestionsExpanded,
        ENFORCEMENT_TYPE.PHISHING,
        {
          condition:
            orgData?.customer_type === CustomerType.CRYPTO &&
            isCryptoEnabledProductType,
          questions: PHISHING_CRYPTO_QUESTIONS,
        },
      )}
    </Stack>
  )

  if (showSuggestedEnforcementsDisplay) {
    return (
      <DetailModal
        handleClickOutside={() => {
          setShowSuggestedEnforcementsDisplay(false)
          returnToTableView()
        }}
        overlayRef={overlayRef}
      >
        {SUGGESTED_ENFORCEMENT_ENABLED_PRODUCTS.includes(
          selectedSpoofReport?.platform_subtype?.product,
        ) && (
          <SuggestedEnforcementsDisplay
            addSuggestedEnforcementsToForm={addSuggestedEnforcementsToForm}
            alreadyStagedEnforcements={currentStagedEnforcements}
            closeSuggestedEnforcementsDisplay={() =>
              setShowSuggestedEnforcementsDisplay(false)
            }
            formResponse={formData}
            product={product}
            returnToTableView={returnToTableView}
          />
        )}
      </DetailModal>
    )
  }

  const shouldRenderEnforcementTypeSelector =
    product !== ProductType.SOCIAL_MEDIA ||
    (product === ProductType.SOCIAL_MEDIA && selectedContentTypeDisplayName !== '')

  return (
    <Flex width={'1000px'}>
      <DetailNavBar
        onBackPressed={closeSuggestedEnforcementsForm}
        onExitTapped={returnToTableView}
      ></DetailNavBar>

      <VStack
        align={'left'}
        marginBottom={'50px'}
        marginTop={'70px'}
        mx={'50px'}
        overflowY={'auto'}
        spacing={30}
        width="100%"
      >
        <Heading as="h4" fontSize={20} size="sm">
          Suggested Enforcements Form
        </Heading>

        <Heading as="h4" fontSize={15} size="sm">
          Please fill out the following questions to help accurately generate suggested
          enforcements for the alert!
        </Heading>

        <SpoofDetailImagePreview isCondensedView={true} />

        {renderFlaggedUrl(selectedSpoofReport?.flagged_url)}

        {CustomerRubricSection}

        {shouldGoDirectlyToSuggestedEnforcementsDisplay ? (
          <></>
        ) : (
          <>
            {isViolatingCustomerRubric && isDomainAlert() && EnforcementUrlTypeSelector}

            {isViolatingCustomerRubric &&
              product === ProductType.SOCIAL_MEDIA &&
              ContentTypeSelector}

            {isViolatingCustomerRubric &&
              shouldRenderEnforcementTypeSelector &&
              EnforcementTypeSelector}

            {isViolatingCustomerRubric && isPhishingSelected && PhishingSection}

            {isViolatingCustomerRubric && isTrademarkSelected && TrademarkSection}

            <Button
              bgColor={DOPPEL_CYBER_BLUE}
              color={'white'}
              isDisabled={isNextButtonDisabled}
              onClick={onSubmit}
              width={'50%'}
            >
              Next
            </Button>
          </>
        )}
      </VStack>
    </Flex>
  )
}

export default withApollo<Props>(SuggestedEnforcementsForm)
